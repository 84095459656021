@import "@styles-v2/common";

.container {
  @include v2-set-svg-color(c(symbol));

  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.white {
  @include v2-set-svg-color(c(base-white));
}
